<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-card :body-style="{padding: '24px 32px'}" :bordered='false'>
      <a-form-model ref='form' :model='form' :rules='rules' layout='inline'>
        <div :style="labelTitle1">会员购买套餐奖励设置（常规套餐）</div>
        <a-divider type='horizontal' />
        <div :style="labelTitle">上级云店单身份时</div>
        <a-form-model-item label='当上级云店消费频率为A时:'></a-form-model-item><br>
        <a-form-model-item label='云店提成:' prop='noMemberSsaDeduct' :labelCol='{ span: 8 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noMemberSsaDeduct' />
        </a-form-model-item>
        <a-form-model-item label='云店提成积分:' prop='noMemberSsaDeductScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noMemberSsaDeductScore' />
        </a-form-model-item>
        <a-form-model-item label='送服务中心积分:' prop='noMemberSsaCenterScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noMemberSsaCenterScore' />
        </a-form-model-item>
        <a-form-model-item label='送运营总裁积分:' prop='noMemberSsaPresidentSocre' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noMemberSsaPresidentSocre' />
        </a-form-model-item><br>
        <a-form-model-item label='当上级云店消费频率为B时:'></a-form-model-item><br>
        <a-form-model-item label='云店提成:' prop='noMemberSsbDeduct' :labelCol='{ span: 8 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noMemberSsbDeduct' />
        </a-form-model-item>
        <a-form-model-item label='云店提成积分:' prop='noMemberSsbDeductScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noMemberSsbDeductScore' />
        </a-form-model-item>
        <a-form-model-item label='送服务中心积分:' prop='noMemberSsbCenterScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noMemberSsbCenterScore' />
        </a-form-model-item>
        <a-form-model-item label='送运营总裁积分:' prop='noMemberSsbPresidentSocre' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noMemberSsbPresidentSocre' />
        </a-form-model-item><br>
        <a-form-model-item label='当上级云店消费频率为C时:'></a-form-model-item><br>
        <a-form-model-item label='云店提成:' prop='noMemberSscDeduct' :labelCol='{ span: 8 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noMemberSscDeduct' />
        </a-form-model-item>
        <a-form-model-item label='云店提成积分:' prop='noMemberSscDeductScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noMemberSscDeductScore' />
        </a-form-model-item>
        <a-form-model-item label='送服务中心积分:' prop='noMemberSscCenterScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noMemberSscCenterScore' />
        </a-form-model-item>
        <a-form-model-item label='送运营总裁积分:' prop='noMemberSscPresidentSocre' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noMemberSscPresidentSocre' />
        </a-form-model-item><br>
        <a-divider type='horizontal' />
        <a-form-model-item label='当上级推荐云店消费频率为A时:'></a-form-model-item><br>
        <a-form-model-item label='云店奖励:' prop='noMemberSrsaReward' :labelCol='{ span: 8 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noMemberSrsaReward' />
        </a-form-model-item>
        <a-form-model-item label='云店奖励积分:' prop='noMemberSrsaRewardScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noMemberSrsaRewardScore' />
        </a-form-model-item><br>
        <a-form-model-item label='当上级推荐云店消费频率为B时:'></a-form-model-item><br>
        <a-form-model-item label='云店奖励:' prop='noMemberSrsbReward' :labelCol='{ span: 8 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noMemberSrsbReward' />
        </a-form-model-item>
        <a-form-model-item label='云店奖励积分:' prop='noMemberSrsbRewardScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noMemberSrsbRewardScore' />
        </a-form-model-item><br>
        <a-form-model-item label='当上级推荐云店消费频率为C时:'></a-form-model-item><br>
        <a-form-model-item label='云店奖励:' prop='noMemberSrscReward' :labelCol='{ span: 8 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noMemberSrscReward' />
        </a-form-model-item>
        <a-form-model-item label='云店奖励积分:' prop='noMemberSrscRewardScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noMemberSrscRewardScore' />
        </a-form-model-item><br>
        <div :style="labelTitle">上级云店拥有双身份时</div>
        <a-form-model-item label='云店提成:' prop='noMemberSssDeduct' :labelCol='{ span: 8 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noMemberSssDeduct' />
        </a-form-model-item>
        <a-form-model-item label='送服务中心积分:' prop='noMemberSssCenterScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noMemberSssCenterScore' />
        </a-form-model-item>
        <a-form-model-item label='送运营总裁积分:' prop='noMemberSssPresidentSocre' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noMemberSssPresidentSocre' />
        </a-form-model-item><br>
        <a-divider type='horizontal' />
        <div :style="labelTitle1">云店购买套餐奖励设置（常规套餐）</div>
        <a-divider type='horizontal' />
        <a-form-model-item label='当推荐云店消费频率为A时:'></a-form-model-item><br>
        <a-form-model-item label='云店提成:' prop='noStoreRsaDeduct' :labelCol='{ span: 8 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noStoreRsaDeduct' />
        </a-form-model-item>
        <a-form-model-item label='云店提成积分:' prop='noStoreRsaDeductScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noStoreRsaDeductScore' />
        </a-form-model-item>
        <a-form-model-item label='送服务中心积分:' prop='noStoreRsaCenterScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noStoreRsaCenterScore' />
        </a-form-model-item>
        <a-form-model-item label='送运营总裁积分:' prop='noStoreRsaPresidentSocre' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noStoreRsaPresidentSocre' />
        </a-form-model-item><br>
        <a-form-model-item label='当推荐云店消费频率为B时:'></a-form-model-item><br>
        <a-form-model-item label='云店提成:' prop='noStoreRsbDeduct' :labelCol='{ span: 8 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noStoreRsbDeduct' />
        </a-form-model-item>
        <a-form-model-item label='云店提成积分:' prop='noStoreRsbDeductScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noStoreRsbDeductScore' />
        </a-form-model-item>
        <a-form-model-item label='送服务中心积分:' prop='noStoreRsbCenterScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noStoreRsbCenterScore' />
        </a-form-model-item>
        <a-form-model-item label='送运营总裁积分:' prop='noStoreRsbPresidentSocre' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noStoreRsbPresidentSocre' />
        </a-form-model-item><br>
        <a-form-model-item label='当推荐云店消费频率为C时:'></a-form-model-item><br>
        <a-form-model-item label='云店提成:' prop='noStoreRscDeduct' :labelCol='{ span: 8 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noStoreRscDeduct' />
        </a-form-model-item>
        <a-form-model-item label='云店提成积分:' prop='noStoreRscDeductScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noStoreRscDeductScore' />
        </a-form-model-item>
        <a-form-model-item label='送服务中心积分:' prop='noStoreRscCenterScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noStoreRscCenterScore' />
        </a-form-model-item>
        <a-form-model-item label='送运营总裁积分:' prop='noStoreRscPresidentSocre' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noStoreRscPresidentSocre' />
        </a-form-model-item><br>
        <a-divider type='horizontal' />
        <a-form-model-item label='当间推云店消费频率为A时:'></a-form-model-item><br>
        <a-form-model-item label='云店提成:' prop='noStoreJrsaDeduct' :labelCol='{ span: 8 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noStoreJrsaDeduct' />
        </a-form-model-item>
        <a-form-model-item label='云店奖励积分:' prop='noStoreJrsaRewardScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noStoreJrsaRewardScore' />
        </a-form-model-item><br>
        <a-form-model-item label='当间推云店消费频率为B时:'></a-form-model-item><br>
        <a-form-model-item label='云店提成:' prop='noStoreJrsbDeduct' :labelCol='{ span: 8 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noStoreJrsbDeduct' />
        </a-form-model-item>
        <a-form-model-item label='云店奖励积分:' prop='noStoreJrsbRewardScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noStoreJrsbRewardScore' />
        </a-form-model-item><br>
        <a-form-model-item label='当间推云店消费频率为C时:'></a-form-model-item><br>
        <a-form-model-item label='云店提成:' prop='noStoreJrscDeduct' :labelCol='{ span: 8 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noStoreJrscDeduct' />
        </a-form-model-item>
        <a-form-model-item label='云店奖励积分:' prop='noStoreJrscRewardScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.noStoreJrscRewardScore' />
        </a-form-model-item><br>
        <a-divider type='horizontal' />
        <div :style="labelTitle1">会员购买套餐奖励设置（尊享套餐）</div>
        <a-form-model-item label='云店提成:' prop='zxMemberStoreDeduct' :labelCol='{ span: 8 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.zxMemberStoreDeduct' />
        </a-form-model-item>
        <a-form-model-item label='云店提成积分:' prop='zxMemberStoreScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.zxMemberStoreScore' />
        </a-form-model-item>
        <a-form-model-item label='云店奖励:' prop='zxMemberStoreReward' :labelCol='{ span: 8 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.zxMemberStoreReward' />
        </a-form-model-item>
        <a-form-model-item label='云店奖励积分:' prop='zxMemberStoreRewardScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.zxMemberStoreRewardScore' />
        </a-form-model-item>
        <a-form-model-item label='送运营总裁积分:' prop='zxMemberPresidentScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.zxMemberPresidentScore' />
        </a-form-model-item><br>
        <a-divider type='horizontal' />
        <div :style="labelTitle1">云店购买套餐奖励设置（尊享套餐）</div>
        <a-form-model-item label='云店提成:' prop='zxStoreStoreDeduct' :labelCol='{ span: 8 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.zxStoreStoreDeduct' />
        </a-form-model-item>
        <a-form-model-item label='云店提成积分:' prop='zxStoreStoreScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.zxStoreStoreScore' />
        </a-form-model-item>
        <a-form-model-item label='云店奖励:' prop='zxStoreStoreReward' :labelCol='{ span: 8 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.zxStoreStoreReward' />
        </a-form-model-item>
        <a-form-model-item label='云店奖励积分:' prop='zxStoreStoreRewardScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.zxStoreStoreRewardScore' />
        </a-form-model-item>
        <a-form-model-item label='送运营总裁积分:' prop='zxStorePresidentScore' labelAlign="left" :labelCol='{ span: 10 }' :wrapperCol='{ span: 6 }'>
          <a-input v-model='form.zxStorePresidentScore' />
        </a-form-model-item><br>
        <a-divider type='horizontal' />
        <a-form-model-item>
          <a-button @click='handleCancel'>
            取消
          </a-button>
          <a-divider type='vertical' />
          <a-button type='primary' @click='handleSubmit'>
            提交
          </a-button>
        </a-form-model-item>
      </a-form-model>

    </a-card>
  </page-header-wrapper>
</template>

<script>
import {
  getMealCategory,
  putMealCategoryPost
} from '@/api/meal'

export default {
  name: 'ProductionMealTypeEdit',
  data() {
    return {
      labelTitle: {
        "font-size": "20px",
        "color": "red",
        "font-weight": "bold"
      },
      labelTitle1: {
        "font-size": "20px",
        "font-weight": "bold"
      },
      form: {},
      rules: {
        noMemberSsaDeduct: [{ required: true, message: '请填写', trigger: 'change' }],
        noMemberSsaDeductScore: [{ required: true, message: '请填写', trigger: 'change' }],
        noMemberSsaCenterScore: [{ required: true, message: '请填写', trigger: 'change' }],
        noMemberSsaPresidentSocre: [{ required: true, message: '请填写', trigger: 'change' }],
        noMemberSsbDeduct: [{ required: true, message: '请填写', trigger: 'change' }],
        noMemberSsbDeductScore: [{ required: true, message: '请填写', trigger: 'change' }],
        noMemberSsbCenterScore: [{ required: true, message: '请填写', trigger: 'change' }],
        noMemberSsbPresidentSocre: [{ required: true, message: '请填写', trigger: 'change' }],
        noMemberSscDeduct: [{ required: true, message: '请填写', trigger: 'change' }],
        noMemberSscDeductScore: [{ required: true, message: '请填写', trigger: 'change' }],
        noMemberSscCenterScore: [{ required: true, message: '请填写', trigger: 'change' }],
        noMemberSscPresidentSocre: [{ required: true, message: '请填写', trigger: 'change' }],
        noMemberSrsaReward: [{ required: true, message: '请填写', trigger: 'change' }],
        noMemberSrsaRewardScore: [{ required: true, message: '请填写', trigger: 'change' }],
        noMemberSrsbReward: [{ required: true, message: '请填写', trigger: 'change' }],
        noMemberSrsbRewardScore: [{ required: true, message: '请填写', trigger: 'change' }],
        noMemberSrscReward: [{ required: true, message: '请填写', trigger: 'change' }],
        noMemberSrscRewardScore: [{ required: true, message: '请填写', trigger: 'change' }],
        noMemberSssDeduct: [{ required: true, message: '请填写', trigger: 'change' }],
        noMemberSssCenterScore: [{ required: true, message: '请填写', trigger: 'change' }],
        noMemberSssPresidentSocre: [{ required: true, message: '请填写', trigger: 'change' }],
        noStoreRsaDeduct: [{ required: true, message: '请填写', trigger: 'change' }],
        noStoreRsaDeductScore: [{ required: true, message: '请填写', trigger: 'change' }],
        noStoreRsaCenterScore: [{ required: true, message: '请填写', trigger: 'change' }],
        noStoreRsaPresidentSocre: [{ required: true, message: '请填写', trigger: 'change' }],
        noStoreRsbDeduct: [{ required: true, message: '请填写', trigger: 'change' }],
        noStoreRsbDeductScore: [{ required: true, message: '请填写', trigger: 'change' }],
        noStoreRsbCenterScore: [{ required: true, message: '请填写', trigger: 'change' }],
        noStoreRsbPresidentSocre: [{ required: true, message: '请填写', trigger: 'change' }],
        noStoreRscDeduct: [{ required: true, message: '请填写', trigger: 'change' }],
        noStoreRscDeductScore: [{ required: true, message: '请填写', trigger: 'change' }],
        noStoreRscCenterScore: [{ required: true, message: '请填写', trigger: 'change' }],
        noStoreRscPresidentSocre: [{ required: true, message: '请填写', trigger: 'change' }],
        noStoreJrsaDeduct: [{ required: true, message: '请填写', trigger: 'change' }],
        noStoreJrsaRewardScore: [{ required: true, message: '请填写', trigger: 'change' }],
        noStoreJrsbDeduct: [{ required: true, message: '请填写', trigger: 'change' }],
        noStoreJrsbRewardScore: [{ required: true, message: '请填写', trigger: 'change' }],
        noStoreJrscDeduct: [{ required: true, message: '请填写', trigger: 'change' }],
        noStoreJrscRewardScore: [{ required: true, message: '请填写', trigger: 'change' }],
        zxMemberStoreDeduct: [{ required: true, message: '请填写', trigger: 'change' }],
        zxMemberStoreScore: [{ required: true, message: '请填写', trigger: 'change' }],
        zxMemberStoreReward: [{ required: true, message: '请填写', trigger: 'change' }],
        zxMemberStoreRewardScore: [{ required: true, message: '请填写', trigger: 'change' }],
        zxMemberPresidentScore: [{ required: true, message: '请填写', trigger: 'change' }],
        zxStoreStoreDeduct: [{ required: true, message: '请填写', trigger: 'change' }],
        zxStoreStoreScore: [{ required: true, message: '请填写', trigger: 'change' }],
        zxStoreStoreReward: [{ required: true, message: '请填写', trigger: 'change' }],
        zxStoreStoreRewardScore: [{ required: true, message: '请填写', trigger: 'change' }],
        zxStorePresidentScore: [{ required: true, message: '请填写', trigger: 'change' }],
      }
    }
  },
  created() {
    this.handleInitial()
  },
  methods: {
    handleCancel(e) {
      this.$router.back()
    },
    handleInitial() {
      const { id } = this.$route.query
      if (id) {
        getMealCategory(this.$route.query).then(result => {
          this.form = Object.assign({}, this.form, result)
        })
      }
    },
    async handleSubmit(e) {
      e.preventDefault()
      this.$refs.form.validate((result) => {
        if (result) {
          putMealCategoryPost(this.form).then(res => {
            this.$router.back()
          })
        }
      })
    }

  }
}
</script>